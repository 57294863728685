var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PopUp',{attrs:{"show":_vm.modal},on:{"hideModal":_vm.hideModal}},[_c('ValidationObserver',{ref:"observer",staticClass:"artist",attrs:{"tag":"div","id":"scroll-block"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('header',{staticClass:"artist__header"},[_c('h3',{staticClass:"artist__header_title"},[_vm._v(_vm._s(_vm.artist.id ? "Edit" : "Add")+" Artist")]),_c('div',{staticClass:"artist__header_actions"},[(_vm.item)?_c('BaseButton',{staticClass:"button--sm button--outline button--uppercase",attrs:{"text":"Delete Artist"},on:{"click":function($event){return _vm.$emit('removeArtist', _vm.artist)}}}):_vm._e(),_c('BaseButton',{staticClass:"button--sm button--green button--uppercase",attrs:{"text":"Save Changes","loading":_vm.changesLoading,"disabled":invalid || _vm.artist.search_image === '' || _vm.artist.header_image === '' || !_vm.changed},on:{"click":_vm.saveArtist}})],1)]),_c('section',{staticClass:"form"},[_c('h3',{staticClass:"form__header"},[_vm._v("Basic Information")]),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ required: true, min: 2, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"First Name","required":true,"validateError":errors[0],"element":_vm.artist.first_name},on:{"input":function($event){_vm.artist.first_name = $event}},model:{value:(_vm.artist.first_name),callback:function ($$v) {_vm.$set(_vm.artist, "first_name", $$v)},expression:"artist.first_name"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ required: true, min: 2, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Last Name","required":true,"validateError":errors[0],"element":_vm.artist.last_name},on:{"input":function($event){_vm.artist.last_name = $event}},model:{value:(_vm.artist.last_name),callback:function ($$v) {_vm.$set(_vm.artist, "last_name", $$v)},expression:"artist.last_name"}})]}}],null,true)})],1),_c('div',{staticClass:"form__row form__row--align-start"},[_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ required: true, path: true, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Path","required":true,"validateError":errors[0] || _vm.serverError.slug,"element":_vm.artist.slug},on:{"input":function($event){_vm.artist.slug = $event}},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('span',{staticClass:"form-control__simple-text"},[_vm._v("Example: "+_vm._s((_vm.url + "/artists/" + (_vm.artist.slug || "path"))))])]},proxy:true}],null,true),model:{value:(_vm.artist.slug),callback:function ($$v) {_vm.$set(_vm.artist, "slug", $$v)},expression:"artist.slug"}})]}}],null,true)}),_c('div',{staticClass:"form__row_col form__row_col--side"},[_c('Toggle',{attrs:{"placeholder":"Status","disabled":invalid || _vm.artist.search_image === '' || _vm.artist.header_image === '',"selected":+_vm.artist.status,"items":[
              { id: '1', title: 'Active' },
              { id: '0', title: 'Inactive' } ]},on:{"input":function($event){_vm.artist.status = $event}},model:{value:(_vm.artist.status),callback:function ($$v) {_vm.$set(_vm.artist, "status", $$v)},expression:"artist.status"}})],1)],1),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ required: true }}},[_c('BaseSelect',{attrs:{"placeholder":"Region","selected":_vm.artist.region_id,"items":_vm.regions,"required":true},on:{"input":function($event){_vm.artist.region_id = $event.id}},model:{value:(_vm.artist.region_id),callback:function ($$v) {_vm.$set(_vm.artist, "region_id", $$v)},expression:"artist.region_id"}})],1),_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div"}},[_c('BaseSelect',{attrs:{"placeholder":"Classification","selected":_vm.artist.is_secondary_market,"items":[
              { id: '0', title: 'Our Artist' },
              { id: '1', title: 'Secondary Market' },
              { id: '2', title: 'Unlisted' } ],"required":true},on:{"input":function($event){_vm.artist.is_secondary_market = $event.id}},model:{value:(_vm.artist.is_secondary_market),callback:function ($$v) {_vm.$set(_vm.artist, "is_secondary_market", $$v)},expression:"artist.is_secondary_market"}})],1)],1)]),_c('section',{staticClass:"form"},[_c('h3',{staticClass:"form__header"},[_vm._v("Biography")]),_c('ValidationProvider',{staticClass:"form__row",attrs:{"tag":"div","rules":{ required: true }}},[_c('Editor',{attrs:{"placeholder":"Text Editor","required":true,"value":_vm.artist.biography},on:{"input":function($event){_vm.artist.biography = $event}},model:{value:(_vm.artist.biography),callback:function ($$v) {_vm.$set(_vm.artist, "biography", $$v)},expression:"artist.biography"}})],1),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ min: 4, max: 4 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('BaseNumberInput',{attrs:{"placeholder":"Birth Year","validateError":errors[0],"element":_vm.artist.birth_year},on:{"input":function($event){_vm.artist.birth_year = $event}},model:{value:(_vm.artist.birth_year),callback:function ($$v) {_vm.$set(_vm.artist, "birth_year", $$v)},expression:"artist.birth_year"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ min: 4, max: 4 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('BaseNumberInput',{attrs:{"placeholder":"Death Year","validateError":errors[0],"element":_vm.artist.death_year},on:{"input":function($event){_vm.artist.death_year = $event}},model:{value:(_vm.artist.death_year),callback:function ($$v) {_vm.$set(_vm.artist, "death_year", $$v)},expression:"artist.death_year"}})]}}],null,true)})],1),_c('ValidationProvider',{staticClass:"form__row_col",attrs:{"tag":"div","rules":{ max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Location","validateError":errors[0],"element":_vm.artist.location},on:{"input":function($event){_vm.artist.location = $event}},model:{value:(_vm.artist.location),callback:function ($$v) {_vm.$set(_vm.artist, "location", $$v)},expression:"artist.location"}})]}}],null,true)})],1),_c('section',{staticClass:"form"},[_c('h3',{staticClass:"form__header"},[_vm._v("CV highlights")]),_c('div',{staticClass:"form__row"},[_c('Editor',{attrs:{"placeholder":"Text Editor","value":_vm.artist.past_shows_and_fair_booths},on:{"input":function($event){_vm.artist.past_shows_and_fair_booths = $event}},model:{value:(_vm.artist.past_shows_and_fair_booths),callback:function ($$v) {_vm.$set(_vm.artist, "past_shows_and_fair_booths", $$v)},expression:"artist.past_shows_and_fair_booths"}})],1),_c('ValidationProvider',{staticClass:"form__row_col",attrs:{"tag":"div","rules":{ max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"View More Button URL","validateError":errors[0],"element":_vm.artist.view_more_url},on:{"input":function($event){_vm.artist.view_more_url = $event}},model:{value:(_vm.artist.view_more_url),callback:function ($$v) {_vm.$set(_vm.artist, "view_more_url", $$v)},expression:"artist.view_more_url"}})]}}],null,true)})],1),_c('section',{staticClass:"form"},[_c('h3',{staticClass:"form__header"},[_vm._v("Images")]),_c('div',{staticClass:"form__row"},[_c('div',{staticClass:"form__row_col form__row_col--side form__row_col--bordered"},[_c('span',{staticClass:"form__row_label"},[_vm._v("Search Image "),_c('span',{staticClass:"form__row_label_required"},[_vm._v("*")])]),_c('DragAndDropImage',{staticClass:"drag-and-drop--full-width",attrs:{"id":"search","size":"15","image":_vm.artist.search_image,"v-model":_vm.artist.search_image},on:{"input":function($event){_vm.artist.search_image = $event.image;
              $event.removed ? _vm.removedImages.push($event.removed) : false;},"removeImage":function($event){_vm.removedImages.push($event);
              _vm.artist.search_image = '';}}})],1),_c('div',{staticClass:"form__row_col form__row_col--side form__row_col--bordered"},[_c('span',{staticClass:"form__row_label"},[_vm._v("Header Image "),_c('span',{staticClass:"form__row_label_required"},[_vm._v("*")])]),_c('DragAndDropImage',{staticClass:"drag-and-drop--full-width",attrs:{"id":"header","size":"15","image":_vm.artist.header_image,"v-model":_vm.artist.header_image},on:{"input":function($event){_vm.artist.header_image = $event.image;
              $event.removed ? _vm.removedImages.push($event.removed) : false;},"removeImage":function($event){_vm.removedImages.push($event);
              _vm.artist.header_image = '';}}})],1)])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }