<template>
  <PopUp :show="modal" @hideModal="hideModal">
    <ValidationObserver
      v-slot="{ invalid }"
      tag="div"
      ref="observer"
      class="artist"
      id="scroll-block"
    >
      <header class="artist__header">
        <h3 class="artist__header_title">{{ artist.id ? "Edit" : "Add" }} Artist</h3>
        <div class="artist__header_actions">
          <BaseButton
            v-if="item"
            class="button--sm button--outline button--uppercase"
            text="Delete Artist"
            @click="$emit('removeArtist', artist)"
          ></BaseButton>
          <BaseButton
            class="button--sm button--green button--uppercase"
            text="Save Changes"
            :loading="changesLoading"
            :disabled="
              invalid || artist.search_image === '' || artist.header_image === '' || !changed
            "
            @click="saveArtist"
          ></BaseButton>
        </div>
      </header>
      <section class="form">
        <h3 class="form__header">Basic Information</h3>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ required: true, min: 2, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="First Name"
              :required="true"
              :validateError="errors[0]"
              v-model="artist.first_name"
              :element="artist.first_name"
              @input="artist.first_name = $event"
            ></BaseInput>
          </ValidationProvider>
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ required: true, min: 2, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Last Name"
              :required="true"
              :validateError="errors[0]"
              v-model="artist.last_name"
              :element="artist.last_name"
              @input="artist.last_name = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row form__row--align-start">
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ required: true, path: true, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Path"
              :required="true"
              :validateError="errors[0] || serverError.slug"
              v-model="artist.slug"
              :element="artist.slug"
              @input="artist.slug = $event"
            >
              <template #text>
                <span class="form-control__simple-text"
                  >Example: {{ `${url}/artists/${artist.slug || "path"}` }}</span
                ></template
              >
            </BaseInput>
          </ValidationProvider>
          <div class="form__row_col form__row_col--side">
            <Toggle
              placeholder="Status"
              v-model="artist.status"
              :disabled="invalid || artist.search_image === '' || artist.header_image === ''"
              :selected="+artist.status"
              :items="[
                { id: '1', title: 'Active' },
                { id: '0', title: 'Inactive' },
              ]"
              @input="artist.status = $event"
            ></Toggle>
          </div>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ required: true }"
          >
            <BaseSelect
              placeholder="Region"
              v-model="artist.region_id"
              :selected="artist.region_id"
              :items="regions"
              :required="true"
              @input="artist.region_id = $event.id"
            ></BaseSelect>
          </ValidationProvider>
          <ValidationProvider tag="div" class="form__row_col form__row_col--side">
            <BaseSelect
              placeholder="Classification"
              v-model="artist.is_secondary_market"
              :selected="artist.is_secondary_market"
              :items="[
                { id: '0', title: 'Our Artist' },
                { id: '1', title: 'Secondary Market' },
                { id: '2', title: 'Unlisted' },
              ]"
              :required="true"
              @input="artist.is_secondary_market = $event.id"
            ></BaseSelect>
          </ValidationProvider>
        </div>
      </section>
      <section class="form">
        <h3 class="form__header">Biography</h3>
        <ValidationProvider tag="div" class="form__row" :rules="{ required: true }">
          <Editor
            placeholder="Text Editor"
            :required="true"
            :value="artist.biography"
            @input="artist.biography = $event"
            v-model="artist.biography"
          ></Editor>
        </ValidationProvider>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ min: 4, max: 4 }"
            v-slot="{ errors }"
          >
            <BaseNumberInput
              placeholder="Birth Year"
              :validateError="errors[0]"
              v-model="artist.birth_year"
              :element="artist.birth_year"
              @input="artist.birth_year = $event"
            >
            </BaseNumberInput>
          </ValidationProvider>
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ min: 4, max: 4 }"
            v-slot="{ errors }"
          >
            <BaseNumberInput
              placeholder="Death Year"
              :validateError="errors[0]"
              v-model="artist.death_year"
              :element="artist.death_year"
              @input="artist.death_year = $event"
            >
            </BaseNumberInput>
          </ValidationProvider>
        </div>
        <ValidationProvider
          tag="div"
          class="form__row_col"
          :rules="{ max: 255 }"
          v-slot="{ errors }"
        >
          <BaseInput
            placeholder="Location"
            :validateError="errors[0]"
            v-model="artist.location"
            :element="artist.location"
            @input="artist.location = $event"
          >
          </BaseInput>
        </ValidationProvider>
      </section>
      <section class="form">
        <h3 class="form__header">CV highlights</h3>
        <div class="form__row">
          <Editor
            placeholder="Text Editor"
            :value="artist.past_shows_and_fair_booths"
            @input="artist.past_shows_and_fair_booths = $event"
            v-model="artist.past_shows_and_fair_booths"
          ></Editor>
        </div>
        <ValidationProvider
          tag="div"
          class="form__row_col"
          :rules="{ max: 255 }"
          v-slot="{ errors }"
        >
          <BaseInput
            placeholder="View More Button URL"
            :validateError="errors[0]"
            v-model="artist.view_more_url"
            :element="artist.view_more_url"
            @input="artist.view_more_url = $event"
          >
          </BaseInput>
        </ValidationProvider>
      </section>
      <section class="form">
        <h3 class="form__header">Images</h3>
        <div class="form__row">
          <div class="form__row_col form__row_col--side form__row_col--bordered">
            <span class="form__row_label"
              >Search Image <span class="form__row_label_required">*</span></span
            >
            <DragAndDropImage
              class="drag-and-drop--full-width"
              id="search"
              size="15"
              :image="artist.search_image"
              :v-model="artist.search_image"
              @input="
                artist.search_image = $event.image;
                $event.removed ? removedImages.push($event.removed) : false;
              "
              @removeImage="
                removedImages.push($event);
                artist.search_image = '';
              "
            ></DragAndDropImage>
          </div>
          <div class="form__row_col form__row_col--side form__row_col--bordered">
            <span class="form__row_label"
              >Header Image <span class="form__row_label_required">*</span></span
            >
            <DragAndDropImage
              class="drag-and-drop--full-width"
              id="header"
              size="15"
              :image="artist.header_image"
              :v-model="artist.header_image"
              @input="
                artist.header_image = $event.image;
                $event.removed ? removedImages.push($event.removed) : false;
              "
              @removeImage="
                removedImages.push($event);
                artist.header_image = '';
              "
            ></DragAndDropImage>
          </div>
        </div>
      </section>
    </ValidationObserver>
  </PopUp>
</template>
<script>
import PopUp from "@/components/PopUp.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import BaseButton from "@/components/buttons/BaseButton.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import BaseSelect from "@/components/inputs/BaseSelect.vue";
import BaseNumberInput from "@/components/inputs/BaseNumberInput.vue";
import DragAndDropImage from "@/components/DragAndDropImage.vue";
import Editor from "@/components/inputs/Editor.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Toggle from "@/components/inputs/Toggle.vue";

export default {
  props: { item: { type: Object, default: null }, modal: { type: Boolean, default: false } },
  components: {
    PopUp,
    ValidationObserver,
    ValidationProvider,
    BaseButton,
    BaseInput,
    BaseSelect,
    BaseNumberInput,
    DragAndDropImage,
    Editor,
    Toggle,
  },
  data() {
    return {
      url: process.env.VUE_APP_FRONTEND_URL,
      artist: {
        first_name: "",
        last_name: "",
        slug: "",
        status: "0",
        biography: "",
        birth_year: "",
        death_year: "",
        location: "",
        region_id: "",
        search_image: "",
        header_image: "",
        past_shows_and_fair_booths: "",
        view_more_url: "",
        is_secondary_market: "0",
      },
      removedImages: [],
      changed: false,
    };
  },
  created() {
    if (this.item != null) {
      this.artist = JSON.parse(JSON.stringify(this.item));
    }
  },
  beforeDestroy() {
    this.setError(null);
  },
  computed: {
    ...mapGetters("general", {
      regions: "getRegions",
    }),
    ...mapGetters("artists", {
      serverError: "getServerError",
      changesLoading: "getChangesLoading",
    }),
  },
  watch: {
    artist: {
      handler: function (val, oldVal) {
        if (this.item != null) {
          if (this.$refs.observer.flags.dirty) {
            this.changed = true;
          }
        } else {
          this.changed = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("general", ["apiPostUploadImage"]),
    ...mapMutations("artists", ["setError"]),
    hideModal() {
      if (this.changed) {
        this.$swal({
          html: "<h2>Are you sure you want to exit <br/> without saving?</h2>",
          showCancelButton: true,
          cancelButtonText: "Exit without saving",
          confirmButtonText: "Save changes",
        }).then(result => {
          if (result.value) {
            this.$refs.observer.flags.valid &&
            artist.search_image != "" &&
            artist.header_image != ""
              ? this.saveArtist()
              : this.showToast();
          } else this.$emit("hideModal");
        });
      } else this.$emit("hideModal");
    },
    showToast() {
      this.$toasted.show(`Please fill in all required fields`, {
        duration: 2000,
        type: "error",
      });
    },
    saveArtist() {
      let data = {
        ...this.artist,
      };
      if (this.artist.id) {
        this.$emit("updateArtist", { data: data, imageForRemove: this.removedImages });
      } else {
        this.$emit("createArtist", { data: data, imageForRemove: this.removedImages });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.artist {
  height: 100%;
  padding: 0 15px;
  overflow-y: auto;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4rem;
    @media only screen and (max-width: $xs) {
      flex-direction: column;
      align-items: flex-start;
    }
    &_title {
      font-size: 3.35rem;
      font-weight: 600;
      line-height: 1.2;
      color: $black;
    }
    &_actions {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      @media only screen and (max-width: $xs) {
        justify-content: flex-start;
        width: 100%;
      }
      .button {
        margin-left: 10px;
        @media only screen and (max-width: $xs) {
          margin: 20px 10px 0 0;
        }
      }
    }
  }
}
</style>
